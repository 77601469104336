import React from 'react';
import { Grid, Typography, Fade } from '@mui/material';
import styled from 'styled-components';
import { IProject } from '../../types/project';

const Promo = (props: ChildProps) => {
  const { project } = props;

  return (
    <Fade in={true} timeout={2000}>
      <section>
        <Typography
          variant="h3"
          color="#000"
          sx={{
            display: 'none',
            '@media (max-width: 768px)': {
              display: 'block',
              mt: 2,
              p: 2,
              color: '#fff',
              backgroundColor: project.portal.primaryColor,
            },
          }}
        >
          Promo
        </Typography>
        <InfoText primaryColor={project.portal.primaryColor} dangerouslySetInnerHTML={{ __html: project.portal.promo }} />
      </section>
    </Fade>
  );
};

interface ChildProps {
  project: IProject;
}

const InfoText = styled.div<{ primaryColor?: string }>`
  height: 100%;
  padding: 16px;
  border: 1px solid ${(props) => (props.primaryColor ? props.primaryColor : '#e8ddb2')};
  background-color: rgb(250, 250, 250);
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: rgb(134, 134, 139);
  }
  @media (max-width: 1600px) {
    p {
      font-size: 0.7rem;
    }
  }
`;

export default Promo;
