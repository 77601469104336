import styled from 'styled-components';
import { IProject } from '../../../types/project';
import { IUser } from '../../../types/user';
import { ISchedule } from '../../../types/appointment';

// Project Imports

const ConfirmSubmission = (props: ChildProps) => {
  /* Props */
  const { project, schedules } = props;

  return (
    <FirstPageContainer style={{ width: 'calc(100% + 48px)' }} image={project.imageGetUrl!}>
      <ColorOverlay style={{ width: 'calc(100% + 48px)' }}></ColorOverlay>
      <TextOverlay>{schedules.length ? schedules[0].endText : 'Thank you for booking an appointment! An email will be sent to you confirming your appointment details.'}</TextOverlay>
    </FirstPageContainer>
  );
};

interface ChildProps {
  project: IProject;
  schedules: ISchedule[];
  user: IUser;
}

const FirstPageContainer = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
  margin: -24px;
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100% + 84.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColorOverlay = styled.div`
  height: calc(100% + 48px);
  background: #000;
  opacity: 0.75;
  position: absolute;
`;

const TitleOverlay = styled.div`
  color: #fff;
  filter: brightness(150%);
  font-size: calc(5vw + 10px);
  font-weight: 500;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const TextOverlay = styled.div`
  color: #fff;
  filter: brightness(150%);
  font-size: calc(2vw + 3px);
  font-weight: 500;
  width: 70%;
  margin: auto;
  text-align: center;
`;

export default ConfirmSubmission;
