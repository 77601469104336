import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { Instagram, MenuOpen, Menu } from '@mui/icons-material';

import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { urlName } from '../../utils/Functions';

import Main from './Main';
import NewHotlist from './NewHotlist';
import Information from './Information';
import Assets from './Assets';
import Videos from './Videos';
import Docs from './Docs';
import Promo from './Promo';
import FloorPlans from './FloorPlans';

const MainContainer = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) =>
  theme.unstable_sx({
    flexGrow: 1,
    padding: {
      xs: theme.spacing(-2),
      md: theme.spacing(2),
    },
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: {
      xs: 0,
      md: '-250px',
      lg: '-275px',
      xl: '-325px',
    },
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const ProjectPortal = () => {
  const user = useSelector(selectUser);
  const params = useParams();
  const navigate = useNavigate();
  const { project } = useSelector(selectProject);
  const [pageValue, setPageValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(true);

  const [logInteraction] = useMutation(INTERACTION, {
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_ID) {
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_ANALYTICS_ID,
          gaOptions: {
            userId: user ? user._id : '0',
          },
        },
      ]);
      ReactGA.event({
        category: `${project ? project.name : 'Error'}`,
        action: `${project ? project.name : 'Error'}`,
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: 'xhr', // optional, beacon/xhr/image
      });
    }
  }, [project, user]);

  const listArray = () => {
    let array: IList[] = [
      {
        name: 'Main',
        component: <Main project={project} />,
        link: '',
      },
    ];
    if (user) {
      array.push({
        name: 'Dashboard',
        component: null,
        link: `/dashboard/${urlName(project.name)}`,
      });
    }
    if (project.portal.promo) {
      array.push({
        name: 'Promotions (Limited Time Only)',
        component: <Promo project={project} />,
        link: '',
      });
    }
    if (project.portal.showHotlist) {
      array.push({
        name: 'Price List',
        component: <NewHotlist user={user} project={project} logInteraction={logInteraction} />,
        link: '',
      });
    }
    if (project.portal.showHotlist) {
      array.push({
        name: 'Floor Plans',
        component: <FloorPlans project={project} />,
        link: '',
      });
    }
    if (project.portal.renderings.length) {
      array.push({
        name: 'Building Design',
        component: <Assets project={project} images={[project.portal.renderings]} titles={['Renderings']} />,
        link: '',
      });
    }
    if (project.portal.aerialPhotos.length || project.portal.photos.length) {
      array.push({
        name: 'Photography',
        component: (
          <Assets
            project={project}
            images={[project.portal.photos, project.portal.aerialPhotos]}
            titles={['Neighbourhood Photography', 'Aerial Photography']}
          />
        ),
        link: '',
      });
    }
    if (project.portal.logos.length) {
      array.push({
        name: 'Branding',
        component: <Assets project={project} images={[project.portal.logos]} titles={['Logos']} />,
        link: '',
      });
    }
    if (project.portal.information.length) {
      array.push({
        name: 'Information',
        component: <Information project={project} />,
        link: '',
      });
    }
    if (project.portal.videos.length) {
      array.push({
        name: 'Media',
        component: <Videos portal={project.portal} />,
        link: '',
      });
    }
    if (project.portal.pdfs.length || project.portal.bRoll.length) {
      array.push({
        name: 'Documents',
        component: <Docs project={project} documents={[project.portal.pdfs, project.portal.bRoll]} titles={['Documents', 'Animation']} />,
        link: '',
      });
    }
    if (user && project.portal.createWorksheets) {
      array.push({
        name: 'Create Wishlist Worksheet',
        component: null,
        link: `/dashboard/${urlName(project.name)}/create-worksheets`,
      });
    }
    array.push({
      name: 'Official Website',
      component: null,
      link: `https://${project.portal.officialSite}`,
    });
    array.push({
      name: `Other ${project.developerName ? project.developerName : ''} Projects`,
      component: null,
      link: `/developer/${urlName(project.developerName)}`,
    });

    return array;
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ borderRadius: '16px' }}>{children}</Box>}
      </div>
    );
  }

  const handleRedirect = (link: string, type: string) => {
    logInteraction({ variables: { user: user ? user._id : null, project: project._id, type: type } }).then((res) => {
      window.open(link, '_blank');
    });
  };

  return (
    <Box sx={{ display: window.innerWidth < 768 ? 'block' : 'flex' }}>
      {!params.developerName && params.developerName !== 'dashboard' ? (
        <Menu
          sx={{
            display: !open ? 'block' : 'none',
            position: 'absolute',
            top: 5,
            left: 5,
            color: '#fff',
            zIndex: 2,
            cursor: 'pointer',
            fontSize: '30px',
          }}
          onClick={() => setOpen(true)}
        />
      ) : null}
      <Drawer
        sx={{
          width: {
            xs: open ? '100%' : 0,
            sm: 250,
            lg: 275,
            xl: 325,
          },
          backgroundColor: 'transparent',
          fontFamily: 'Abadi-Standard !important',
          flexShrink: 0,
          position: 'relative',
          '@media (max-width: 768px)': {
            display: 'none',
          },
          display: 'block',
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              sm: 250,
              lg: 275,
              xl: 325,
            },
            top: params.developerName && params.developerName === 'dashboard' ? 64 : 0,
            boxSizing: 'border-box',
            borderRight: `5px solid ${project.portal.primaryColor}`,
          },
        }}
        variant={'persistent'}
        elevation={24}
        anchor="left"
        open={open}
      >
        <Paper elevation={24} sx={{ top: 0 }}>
          {!params.developerName && params.developerName !== 'dashboard' ? (
            <MenuOpen sx={{ position: 'absolute', top: 3, right: 3, cursor: 'pointer' }} onClick={() => setOpen(false)} />
          ) : null}
          {params.developerName !== 'dashboard' ? (
            <Box
              sx={{
                textAlign: 'center',
                mt: 2,
                height: {
                  xs: '75px',
                  desktop: '100px',
                },
                width: 'auto',
                p: 1,
              }}
            >
              <img alt="Logo" src={project.logoGetUrl!} style={{ maxWidth: '100%', height: '100%' }} />
            </Box>
          ) : null}
          <List sx={{ backgroundColor: project.portal.primaryColor, color: project.portal.secondaryColor, p: 0 }}>
            {listArray().map((list, index) => (
              <ListItem key={index} disablePadding sx={{ textAlign: 'center' }}>
                <ListItemButton
                  sx={{
                    textAlign: 'center',
                    boxShadow: 'inset 0 0 0 0 #fff',
                    transition: 'all ease 0.8s',
                    ':hover': {
                      backgroundColor: pageValue !== index ? project.portal.primaryColor : '#fff',
                      boxShadow: pageValue !== index ? 'inset 350px 0 0 0 #fff' : 'none',
                      color: project.portal.primaryColor,
                    },
                    color: pageValue === index ? project.portal.primaryColor : '#fff',
                    backgroundColor: pageValue === index ? '#fff' : project.portal.primaryColor,
                  }}
                  onClick={() => {
                    list.component
                      ? setPageValue(index)
                      : list.name === 'Official Website'
                      ? handleRedirect(`//www.${project.portal.officialSite}`, 'projectSite')
                      : navigate(list.link);
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: 'Abadi-Standard',
                      fontSize: {
                        xs: '0.7rem',
                        desktop: '1rem',
                      },
                    }}
                    primary={list.name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
        <Box
          sx={{
            mt: params.developerName && params.developerName === 'dashboard' ? '16px' : 'auto',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          {params.developerName !== 'dashboard' ? (
            <Box>
              <Box
                sx={{
                  alignSelf: 'center',
                  cursor: 'pointer',
                  width: {
                    xs: '50px',
                    desktop: '75px',
                  },
                  height: 'auto',
                }}
              >
                <a href={`https://rdsbrokerage.com`} target="_black" rel="noreferrer">
                  <img
                    alt="Logo"
                    src="https://rdsappimages.s3.ca-central-1.amazonaws.com/assets/rds/logo.png"
                    style={{ maxWidth: '100%', height: '100%' }}
                  />
                </a>
              </Box>
            </Box>
          ) : null}
          {project.developerLogoGetUrl ? (
            <Box
              sx={{
                alignSelf: 'center',
                cursor: 'pointer',
                width: {
                  xs: '50px',
                  desktop: '75px',
                },
                height: 'auto',
              }}
            >
              <a href={`https://${project.portal.officialSite}`} target="_black" rel="noreferrer">
                <img alt="Logo" src={project.developerLogoGetUrl!} style={{ maxWidth: '100%', height: '100%' }} />
              </a>
            </Box>
          ) : null}
        </Box>
        <Box sx={{ mt: 0, display: 'flex', justifyContent: 'space-evenly' }}>
          <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
            <Instagram
              onClick={() => handleRedirect('https://www.instagram.com/RDSbrokerage/', 'rdsInsta')}
              sx={{ marginRight: '5px', color: '#E4405F' }}
            />
          </Box>
          {project.portal.instaUrl ? (
            <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
              <Instagram
                onClick={() => handleRedirect(project.portal.instaUrl, 'projectInsta')}
                sx={{ marginRight: '5px', color: '#E4405F' }}
              />
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: {
              xs: '0.7rem',
              desktop: '1rem',
            },
          }}
        >
          ® 2024 Residential Development Services
        </Box>
      </Drawer>
      <MainContainer
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          '@media (max-width: 768px)': {
            height: '100vh',
            minHeight: 'auto',
          },
          backgroundImage: !pageValue
            ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${project.imageGetUrl!})`
            : `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${project.imageGetUrl!})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          fontFamily: 'Abadi-Standard !important',
        }}
        open={open}
      >
        {window.innerWidth < 768 ? (
          <>
            <Box
              sx={{
                '@media (max-width: 768px)': {
                  display: 'block',
                },
                display: 'none',
              }}
            >
              {listArray().map((list, index: number) => {
                return list.component;
              })}
              <Box sx={{ p: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Box sx={{ alignSelf: 'center', cursor: 'pointer', width: '75px', height: 'auto' }}>
                    <a href={`https://rdsbrokerage.com`} target="_black" rel="noreferrer">
                      <img
                        alt="Logo"
                        src="https://rdsappimages.s3.ca-central-1.amazonaws.com/assets/rds/logo.png"
                        style={{ maxWidth: '100%', height: '100%' }}
                      />
                    </a>
                  </Box>
                  <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
                    <Instagram
                      onClick={() => handleRedirect('https://www.instagram.com/RDSbrokerage/', 'rdsInsta')}
                      sx={{ marginRight: '5px', color: '#E4405F', fontSize: '60px' }}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>
                  {project.developerLogoGetUrl ? (
                    <Box sx={{ alignSelf: 'center', cursor: 'pointer', width: '75px', height: 'auto' }}>
                      <a href={`https://${project.portal.officialSite}`} target="_black" rel="noreferrer">
                        <img alt="Logo" src={project.developerLogoGetUrl!} style={{ maxWidth: '100%', height: '100%' }} />
                      </a>
                    </Box>
                  ) : null}
                  {project.portal.instaUrl ? (
                    <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
                      <Instagram
                        onClick={() => handleRedirect(project.portal.instaUrl, 'projectInsta')}
                        sx={{ marginRight: '5px', color: '#E4405F', fontSize: '60px' }}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
        <Box
          sx={{
            '@media (max-width: 768px)': {
              display: 'none',
            },
            display: 'block',
          }}
        >
          {listArray().map((list, index) =>
            list.component ? (
              <CustomTabPanel key={index} value={pageValue} index={index}>
                {list.component}
              </CustomTabPanel>
            ) : null
          )}
        </Box>
      </MainContainer>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IList {
  name: string;
  component: any;
  link: string;
}

const INTERACTION = gql`
  mutation logInteraction($user: MongoID, $project: MongoID!, $type: String!, $units: [MongoID]) {
    logInteraction(user: $user, project: $project, type: $type, units: $units)
  }
`;

export default ProjectPortal;
